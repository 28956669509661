import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  let link = this;
  var options = {
    closerText: link.dataset.theme === 'undefined' ? '' : '×',
    theme: typeof link.dataset.theme === 'undefined' ? 'default' : link.dataset.theme,
    closeOnSuccess: false,
    closeOnSuccessDelay: 10000,
    onFormSuccess: function () {
      if (link.dataset.goal) {
        window.goal(link.dataset.goal);
      }
    }
  };
  let modal = new Modal(this, options);
  return false;
});